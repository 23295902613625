import axios from 'axios'

export const internalZipLookup = async (zipCode) => {
  return await axios
    .get(`https://zip.org31415.dev/us/${zipCode}`)
    .then(response => {
      if ('state' in response.data) {
        return {
          county: response.data.county,
          city: response.data.city,
          stateCode: response.data.state,
          zipError: false
        }
      } else {
        return {
          zipError: true
        }
      }
    })
    .catch(e => {
      console.log('zipLookup error:', e)
    })
}

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ipqsIsEmailInvalid = async (email) => {
  let ipqsUrl = 'https://www.ipqualityscore.com/api/json/email'
  let apiKey = 'HfqY4U68y35wxDI5cXPJUojmvSTS3rMy'

  return await axios
    .get(`${ipqsUrl}/${apiKey}/${email}`)
    .then(response => {
      if (!response.data.valid) {
        return true
      } else if (response.data.disposable) {
        return true
      } else return response.data.fraud_score > 90
    })
    .catch(e => {
      console.log('email error:', e)
    })
}

export const ipqsIsPhoneValid = async (phone) => {
  if (phone.length < 14) return true

  //ipqs phone validation only accepts formatted numbers with country code in front
  const formattedNumber = '1' + phone.replace(/[()\-\s]+/g, "")
  const ipqsUrl = 'https://ipqualityscore.com/api/json/phone'
  const apiKey = 'HfqY4U68y35wxDI5cXPJUojmvSTS3rMy'

  return await axios
    .get(`${ipqsUrl}/${apiKey}/${formattedNumber}`)
    .then(response => {
      return !response.data.valid || response.data.fraud_score > 85 || response.data.line_type === 'Toll Free'
    })
    .catch(e => {
      console.log('phone error:', e)
    })
}

export const isNameGibberish = (name) => {
  //checking if name has any prefixes or suffixes is highest priority to ignore the name suffix when running name gibberish tests
  name = removePrefixInNameIfExists(name).trim()
  name = removeSuffixInNameIfExists(name).trim()

  //check if name has a special character that connects name ('-') => if name has hyphen, split the str
  //the reason we choose to only remove hyphens is because if we attempt to remove any special character
  //we are defeating the point of checking for name gibberish, if we checked and removed >?!<| etc, we would end up
  //not flagging an incorrect name

  //check if name has a special character that connects name (' ', '-') => if name has hyphen, and replace with empty space string
  name = name.replace(/[-.,]/m, ' ')

  //remove excess white space
  name = name.replace(/\s+/g, ' ').trim()

  const splitName = name.split(' ')

  //remove any initials
  for (let namePart of splitName) {
    if (namePart.length <= 1) {
      splitName.splice(splitName.indexOf(namePart), 1)
    }
  }

  //iterate through array of split names to test individually only if name has hyphen, otherwise proceed with regular check
  return iterateTests(splitName)
}

const regexNameGibberishTest = (name) => {
  // has less than 2 chars
  if (name.length < 2) {
    return true
  }

  // has no vowels
  if (!/[aeiouyāēīōūȳáéíóúýäöü]/i.test(name)) {
    return true
  }

  // has three consecutive chars
  if (/([A-Za-zÀ-ÖØ-öø-ÿ])\1\1+/.test(name)) {
    return true
  }

  // no vowels in 5 chars
  if (/[^aeiouyāēīōūȳáéíóúýäöü]{5}/mi.test(name)) {
    return true
  }
  return false
}

const removePrefixInNameIfExists = (name) => {
  //remove any prefix in name
  const prefixRegex = /^(hr|fr|md|dr|mr|mrs|miss|ms|sir|sr)(\.|\s+)/i
  if (prefixRegex.test(name)) {
    return name.replace(prefixRegex, "").trim()
  }
  return name
}

const removeSuffixInNameIfExists = (name) => {
  //remove suffix in name
  const suffixRegex = /(?:,|-|\s+)(?:i|ii|iii|iv|jr|sr|dds|phd|md|dvm)\.?$/i
  if (suffixRegex.test(name)) {
    return name.replace(suffixRegex, "").trim()
  }
  return name
}

const iterateTests = (nameArray) => {
  for (const name of nameArray) {
    const regexNameGibberish = regexNameGibberishTest(name)
    if (regexNameGibberish) return true
  }
  return false
}